<template>
  <div class="go-in">
    <banner :img="imgUrl" title="公司简介" />

    <div class="section" v-loading="loading">
      <div class="content-summary">
        <div class="content">
          <Headline title="关于我们" subtitle="ABOUT US"></Headline>
          <div class="summary">
            <h5>江苏微知量子科技有限公司</h5>
            <span class="content">
            致力成为量子信息产业生态链中的一环，为政府、企业提供量子信息技术综合解决方案。公司在网络的量子安全升级、量子安全的密码云/数据云/算力云，以及融合量子计算与经典计算的战略混合集群等领域，能够提供整体解决方案和相应的硬件设备与软件平台、关键设备与产品，母公司易科腾相关产品已被列入江苏省信创图谱,为政府、企业的数据全生命周期安全保驾护航。
            </span>
          </div>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="content-partner">
        <div class="partner">
          <Headline title="合作伙伴" subtitle="RARTNERS"></Headline>
          <div class="img_sty">
            <el-row class="img_row">
              <el-col :span="6" class="img_col">
                <img src="@/assets/images/hz1.png" alt style="width: 150px" />
              </el-col>
              <el-col :span="6" class="img_col">
                <img src="@/assets/images/hz2.png" alt style="width: 150px" />
              </el-col>
              <el-col :span="6" class="img_col">
                <img src="@/assets/images/hz3.png" alt style="width: 150px" />
              </el-col>
              <el-col :span="6" class="img_col">
                <img src="@/assets/images/hz4.png" alt style="width: 150px" />
              </el-col>
            </el-row>
            <el-row class="img_row">
              <el-col :span="6" class="img_col">
                <img src="@/assets/images/hz5.png" alt style="width: 150px" />
              </el-col>
              <el-col :span="6" class="img_col">
                <img src="@/assets/images/hz6.png" alt style="width: 150px" />
              </el-col>
              <el-col :span="6" class="img_col">
                <img src="@/assets/images/hz7.png" alt style="width: 150px" />
              </el-col>
              <el-col :span="6" class="img_col">
                <img src="@/assets/images/hz8.png" alt style="width: 150px" />
              </el-col>
            </el-row>
            <el-row class="img_row">
              <el-col :span="6" class="img_col">
                <img src="@/assets/images/hz9.png" alt style="width: 150px" />
              </el-col>
              <el-col :span="6" class="img_col">
                <img src="@/assets/images/hz10.png" alt style="width: 150px" />
              </el-col>
              <el-col :span="6" class="img_col">
                <img src="@/assets/images/hz11.png" alt style="width: 150px" />
              </el-col>
              <el-col :span="6" class="img_col">
                <img src="@/assets/images/hz12.png" alt style="width: 150px" />
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/Banner";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    Headline: () => import("../components/headline"),
    Banner,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      loading: false,
      imgUrl: require("@/assets/images/bage1.png")
    };
  },
  mounted() {
    window.scrollTo(0,0);
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.go-in {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .section {
    width: 100%;
    margin: 0 auto;
    .content-summary {
      // background-color: #eff4ff;
      .content{
        width: 1240px;
        margin: 0 auto;
        padding: 100px 0;
        .summary {
          margin: 0 auto;
          margin-top: 70px;
          font-family: Source Han Sans CN;
          h5 {
            font-size: 48px;
            color: #3471F3;
            line-height: 60px;
            display: contents;
          }
          .content {
            font-size: 25px;
            color: #333333;
            line-height: 60px;
          }
        }
      }
    }

    //合作伙伴
    .content-partner {
      background-color: #eff4ff;
      padding: 70px 0;
      .partner{
        width: 1240px;
        margin: 0 auto;
        .img_sty {
          margin-top: 70px;
          .img_row{
            display: flex;
            justify-content: center;
            .img_col{
              width: 280px;
              height: 100px;
              line-height: 70px;
              text-align: center;
              padding: 20px;
              margin: 15px;
              background: url(../assets/images/white.png) no-repeat center;
              background-size: cover;
            }
          }
          img {
            width: 200px;
          }
        }
      }
    }
  }
}

</style>
